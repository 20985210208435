import styled from 'styled-components';

export const Wrapper = styled.div`
	position: relative;
	display: inline-flex;
	width: 25px;
	height: 25px;

	.free-left-value {
		position: absolute;
		border-radius: 6px;
		background-color: #fd3435;
		top: -2px;
		right: 0;
		width: 8px;
		height: 11px;
		line-height: 11px;
		text-align: center;
		color: white;
		font-size: 8px;
	}
`;